




















// @vuese
// @group Tables
// Applications Table

import {
  EditAccount as EditAccountRoute, 
  LicenseManager as LicenseManagerRoute,
} from '@/router'

import { Store , TAwait} from '@/store/store';
import Component from 'vue-class-component'
import { Emit } from 'vue-property-decorator';
import {Getter} from 'vuex-class'
import TableLoading from './TableLoading.vue'
/**
 * 
 * This component renders a list of applications that the user can view and manage
 * 
 */

interface DistApp {
  status: string;
  manage: boolean;
}

@Component({
  components:{
    TableLoading
  }
})
export default class ApplicationsTable extends Store {
  componentTitle = 'Customers Table';

  items: TAwait<ReturnType<typeof Store.prototype.getListOfDistributorApplications>> = []
  fields = [
    {key: 'company_name', label: 'Company', tdClass: 'title'},
    'created_at',
    {key: 'status', label: 'Status', tdClass: 'status'},
    {key: 'manage', label: '', tdClass: 'w10'},
  ]

  async mounted(){
    await this.refresh()
  }
  /**
   * Refreshes the table data
   * @vuese
   */
  async refresh(){
    let data = await this.getListOfDistributorApplications()
    
    if(!data || data.length < 1){
      this.$toast.error("No Applications To View Right Now")
      data = []
    }

    this.items = data
    
    this.items.map((item: DistApp) => {
      if(item.status == 'pending'){
        item.manage = true
      }
    })
  }

  /**
   * Redirects the user to another page
   * @arg {any} user - Account details
   * @vuese
   */
  manageAccount(user: {id: number | string}){
    if(!user.id){
      return this.$toast.error('There Was An Error Managing This Account')
    }
    this.$router.push(EditAccountRoute.path.replace(/:id/g, user.id.toString()))
  }
    /**
   * Redirects the user to another page
   * @arg {any} user - Account details
   * @vuese
   */
  deleteAccount(user: any){
    this.$toast.error('Not Implemented Yet')
  }
  openLicenseManagerPage(user: any){
    if(!user.id){
      this.$toast.error('There Was An Getting The Licenses of This Account')
    }
    this.$router.push(LicenseManagerRoute.path.replace(/:id/g, user.id.toString()))
  }

  @Emit()
  getApplication(id: number){
    return id
  }
}
