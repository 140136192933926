
































































































































import {Store, TAwait} from '@/store/store'
import Component from 'vue-class-component'

import ApplicationsTable from '../../components/tables/ApplicationsTable.vue';
import GenericForm from '@/components/forms/GenericForm.vue'
import ConfirmPopup from '@/components/popup/ConfirmationPopup.vue'

@Component({
  components: {
    ApplicationsTable,
    GenericForm,
    ConfirmPopup,
  },
  metaInfo: {
    title: 'Distributor Applications',
  }
})
export default class Applications extends Store {
    componentTitle = 'Distributor Applications'
    selectedApplication: TAwait<ReturnType<typeof Store.prototype.getDistributorApplication>> | null = null
    showManualInvoiceConfirm = false

    get selectedApplicationIsPendingDecision(): boolean {
      return this.selectedApplication?.status === 'pending'
    }

    async getApplication(index: number){ 
      const data = await this.getDistributorApplication(index)
      this.selectedApplication = data;
      return data
    }

    /**
     * Approve a given application
     */
    approve(application: any, ack?: boolean){

      if(!application.has_manual_invoices) null
      else if(ack == undefined) return this.showManualInvoiceConfirm = true
      
      this.showManualInvoiceConfirm = false

      this.approveDistributorApplication({
        id: +application.id,
        form:{
          //@ts-ignore
          has_manual_invoices: ack
        }
      }).then(() => {
        
        this.$toast.success(`${application.company_name} Application Approved`)
        this.closeApplicationEditor()
        // this.refresh()
      }).catch(() => {
        this.$toast.error("Failed To Approve This Application")
      })
    }

    deny(application: any){
      this.denyDistributorApplication(application.id).then(() => {
        this.$toast.error(`${application.company_name} Application Denied`)
        this.closeApplicationEditor()
        // this.refresh()
      }).catch(() => {
        this.$toast.error("Failed To Deny This Application")
      })
    }

    closeApplicationEditor(){
      this.selectedApplication = null
    }
}
